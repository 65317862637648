<template>
  <div>
    <!-- ======= Header ======= -->
    <header
      id="header"
      class="fixed-top header-white"
    >
      <div class="container d-flex align-items-center justify-content-between">

        <a
          href="/"
          class="logo logo-white mr-auto"
        >
          <img
            src="@/assets/images/logo-color.svg"
            alt="Modalmu Logo"
            class="img-fluid"
            height="30px"
          >
        </a>

        <div class="d-flex flex-row align-items-center">
          <nav class="nav-menu d-none d-lg-block">
            <ul>
              <li>
                <router-link to="/">
                  {{ $t('navbar.home') }}
                </router-link>
              </li>
              <li>
                <router-link to="/contact-us">
                  {{ $t('navbar.contactUs') }}
                </router-link>
              </li>
            </ul>
          </nav><!-- .nav-menu -->
          <language-switcher />
          <div
            v-b-toggle.sidebar_menu
            class="open-btn d-block d-lg-none d-xl-none"
          >
            <b-icon-list />
          </div>
        </div>
      </div>
    </header><!-- End Header -->

    <!-- Offcanvas Menu -->
    <b-sidebar
      id="sidebar_menu"
      title="Sidebar"
      right
      shadow
      no-header
    >
      <div
        v-b-toggle.sidebar_menu
        class="close-btn"
      >
        <b-icon-x />
      </div>
      <div class="px-3 pb-2">
        <div class="sidebar-header">
          <img
            src="@/assets/images/logo-color.svg"
            alt="Modalmu Logo"
            class="img-fluid"
            height="30px"
          >
        </div>
        <ul class="nav-items ">
          <li>
            <router-link
              id="menu-home"
              to="/"
              class="sidebar-menu-item active"
              @click="activateThis('menu-home')"
            >
              <div class="d-inline-block">
                <b-icon-house-door />
              </div>
              <div class="d-inline-block">
                {{ $t('navbar.home') }}
              </div>
            </router-link>
          </li>
          <li>
            <router-link
              id="contact-us"
              to="/contact-us"
              class="sidebar-menu-item"
              @click="activateThis('contact-us')"
            >
              <div class="d-inline-block">
                <b-icon-people />
              </div>
              <div class="d-inline-block">
                {{ $t('navbar.contactUs') }}
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </b-sidebar>
    <!-- End Offcanvas Menu -->
  </div>
</template>

<script>
import LanguageSwitcher from './LanguageSwitcher.vue'

export default {
  components: {
    LanguageSwitcher,
  },
  data() {
    return {}
  },
  mounted() {
    const pathArray = window.location.pathname.split('/')
    if (pathArray[1] !== '') {
      this.activateThis(pathArray[1])
    }
  },
  methods: {
    activateThis(param) {
      const menuActive = document.getElementById(param)
      const menuList = document.querySelectorAll('.sidebar-menu-item')
      menuList.forEach(element => {
        element.classList.remove('active')
      })
      menuActive.classList.add('active')
    },
  },
}
</script>

<style lang="scss" scoped>
  .sidebar-header {
    display: flex;
    img {
      max-width: 140px;
      margin: 0 auto;
    }
  }
</style>
