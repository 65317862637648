<template>
  <div
    class="container mx-auto row justify-content-center"
    data-aos="fade-up"
  >
    <div
      id="profile-info"
      class="col-md-5 d-block text-center"
      data-aos="fade-up"
      data-aos-delay="0"
    >
      <div class="flex flex-column">
        <img
          src="@/assets/images/people/Douglas-Goh.png"
          class="rounded-circle"
          alt="Douglas Goh"
        >
      </div>
    </div>
    <div
      id="profile-desc"
      class="col-md-7 border-left"
      data-aos="fade-up"
      data-aos-delay="0"
    >
      <div class="d-flex flex-column">
        <h2 class="modalmu-blue">
          Douglas Goh
        </h2>
        <h5 class="mb-3">
          Co-Founder / Managing Partner
        </h5>
        <p>{{ $t('profile.douglasGoh1') }}</p>
        <p>{{ $t('profile.douglasGoh2') }}</p>
        <p>{{ $t('profile.douglasGoh3') }}</p>
        <p>{{ $t('profile.douglasGoh4') }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {}
  },
  methods: {
  },
}
</script>

<style scoped>
</style>
