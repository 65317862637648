<template>
  <b-dropdown
    id="lang-switcher"
    :text="$i18n.locale"
    size="sm"
    class=""
  >
    <b-dropdown-item
      v-for="(lang, index) in langs"
      :key="'lang-' + index"
      @click="setLang(lang)"
    >
      {{ lang }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      langs: ['EN', 'ID'],
    }
  },
  methods: {
    setLang(lang) {
      localStorage.lang = lang
      this.$i18n.locale = lang
    },
  },
}
</script>

<style scope>
#lang-switcher  {
  height: 100%;
  width: 100%;
  margin: 0 0 0 2rem;
  border-radius: 15px;
}

#lang-switcher:hover  {
  background-color: #e4e4e4;
}

#lang-switcher .btn {
  font-size: inherit;
  min-width: 60px;
  padding: 0.4rem 0.8rem;
  background-color: transparent;
  color: #2991FF;
  border-color: transparent;
  margin: auto;
  text-align: right;
}

#lang-switcher .btn.btn-blue {
  font-size: inherit;
  min-width: 60px;
  padding: 0.4rem 0.8rem;
  background-color: #2991FF;
  color: rgb(255, 255, 255);
  border-color: #2991FF;
  margin: auto;
  text-align: right;
}

#lang-switcher .btn:focus {
  box-shadow: 0 0 0 0 rgb(130 138 145 / 0%);
}

#lang-switcher ul {
  min-width: 2em;
}

#lang-switcher .dropdown-menu {
  padding: 0;
}

#lang-switcher .dropdown-item {
  color: #2991FF;
  padding: 0.25rem 1.25rem;
}

#lang-switcher .dropdown-item:active {
  color: #ffffff;
  background-color: #2991FF;
}

</style>
