<template>
  <!-- ======= Footer ======= -->
  <footer
    id="footer"
    class="gradient-blue"
  >
    <div class="divider-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="fill-section-bg"
        style="transform: rotate(180deg); margin-top: -1px;"
        preserveAspectRatio="none"
        viewBox="0 0 1280 100"
      >
        <path d="M 0 100 L 1280 100 L 1280 1 C 1173.84 43.649 907.689 75.826 640.502 75.564 C 374.355 75.304 107.167 42.771 0 0 L 0 100 Z" />
      </svg>
    </div>
    <div class="footer-top clearfix">
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-md-6 col-lg-3 footer-contact">
            <div class="widget-title d-flex flex-row">
              <img
                src="@/assets/images/logo-white.svg"
                alt="Modalmu Logo"
                class="image-fluid d-block"
                height="30px"
              >
            </div>
            <div class="row modalmu-email">
              <b-icon-envelope /> <a
                href="mailto:admin@modalmu.com"
                class="ml-3"
              >admin@modalmu.com</a>
            </div>
          </div>

          <div class="col-10 col-sm-8 col-md-6 col-lg-3 footer-links text-center mx-auto">
            <h3>{{ $t('footer.followUs') }}</h3>
            <p class="d-none">
              Description
            </p>
            <div class="social-links d-flex flex-row">
              <div>
                <b-icon-twitter />
              </div>
              <div>
                <b-icon-facebook />
              </div>
              <div>
                <b-icon-instagram />
              </div>
              <div>
                <b-icon-youtube />
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 footer-badge text-white text-center">
            <h3>
              {{ $t('footer.registered') }}
            </h3>
            <img
              src="@/assets/images/art/kominfo.png"
              class="registered-logo"
              height="80px"
            >
          </div>

          <div class="col-md-6 col-lg-3 footer-badge text-white text-center">
            <h3>
              {{ $t('footer.certified') }}
            </h3>
            <img
              src="@/assets/images/art/iso-270001.png"
              class="verified-logo"
            >
          </div>

        </div>
        <hr>
        <div class="copyright">
          Copyright &copy; 2021 Modalmu. Assets by Freepik.
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer -->
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
  },
}
</script>

<style scoped>
</style>
