<template>
  <div>
    <!-- ======= Hero Section ======= -->
    <div
      id="modalmu-no-slide"
      class="page-title-wrapper gradient-blue"
    >
      <div class="container">
        <div class="divider-68 pt-5" />
        <div class="indonesia">
          <div class="row text-center">
            <h1
              class="page-title mb-1"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              {{ $t(title) }}
            </h1>
            <!--/column -->
          </div>
        </div>
        <!--/.row -->
      </div>
      <!-- /.wrapper -->
      <div class="divider divider-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="fill-section-bg"
          preserveAspectRatio="none"
          viewBox="0 0 1280 200"
        >
          <path d="M 0 200L 1280 200 L 1280 1 C 1173.84 43.649 907.689 75.826 640.502 75.564 C 374.355 75.304 107.167 42.771 0 0 L 0 200 Z" />
        </svg>
      </div>
    </div>
    <!-- End Hero -->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
$high_phone: 425px;
$tablet: 768px;

  .divider-68 {
    height: 68px;
  }

  #modalmu-no-slide .indonesia .row {
    padding: 1rem 0 4rem;
  }

  #modalmu-no-slide div.divider {
    margin-top: -4rem;
  }

  #modalmu-no-slide div.divider svg {
    width: 100%;
    height: 100px;
  }

  @media (min-width: 320px) {
    #modalmu-no-slide .indonesia .row {
      padding: 1rem 0 3rem;
    }

    #modalmu-no-slide div.divider {
      margin-top: -3rem;
    }
  }

  @media (max-width: $high_phone) {
    #modalmu-no-slide {
      div {
        h1 {
          font-size: 1.7rem !important;
        }
      }
    }
  }

  @media (min-width: $high_phone) and (max-width: $tablet) {
    #modalmu-no-slide {
      div {
        h1 {
          font-size: 1.9rem !important;
        }
      }
      .container {
        .indonesia {
          .row {
            padding: 2.5rem 0 3rem 0;
          }
        }
      }
    }
  }

  @media (min-width: 772px) {
    #modalmu-no-slide {
      .container {
        .indonesia {
          .row {
            padding: 6rem 0;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    #modalmu-no-slide {
      .container {
        .indonesia {
          .row {
            padding: 10rem 0 !important;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) {
    #modalmu-no-slide div.divider {
      margin-top: 0 !important;
    }
  }

  @media (min-width: 1560px) {
    #modalmu-no-slide {
      div {
        &.divider {
          margin-top: calc(1vh - 7%);
        }
      }
    }
  }
</style>
