<template>
  <div>
    <default-navigation />

    <page-header title="page.team" />

    <main id="main">
      <!-- ======= Contact Section ======= -->
      <section
        v-if="personDetail !== null"
        id="profile"
        class="section-bg"
      >
        <sylvia-kartawijaya v-if="personDetail === 'sylviaKartawijaya'" />
        <ahmad-fuad v-else-if="personDetail === 'ahmadFuad'" />
        <douglas-goh v-else-if="personDetail === 'douglasGoh'" />
        <veronika-tri-wahyuni v-else-if="personDetail === 'veronikaTriWahyuni'" />

      </section><!-- End Contact Section -->

    </main><!-- End #main -->

    <footer-default />

    <a
      href="#"
      class="back-to-top"
    ><i class="ri-arrow-up-line" /></a>
  </div>
</template>

<script>
import DefaultNavigation from '@/components/navigation/DefaultNavigation.vue'
import PageHeader from '@/components/header/PageHeader.vue'
import FooterDefault from '@/components/footer/FooterDefault.vue'
import SylviaKartawijaya from '@/components/profile/SylviaKartawijaya.vue'
import AhmadFuad from '@/components/profile/AhmadFuad.vue'
import DouglasGoh from '@/components/profile/DouglasGoh.vue'
import VeronikaTriWahyuni from '@/components/profile/VeronikaTriWahyuni.vue'

export default {
  components: {
    DefaultNavigation,
    PageHeader,
    FooterDefault,
    SylviaKartawijaya,
    VeronikaTriWahyuni,
    AhmadFuad,
    DouglasGoh,
  },
  props: {
    personDetail: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      profile: null,
    }
  },
  computed: {
  },
  created() {
    window.scrollTo(0, 0)
    if (!this.personDetail) this.$router.push({ name: 'home' })
  },
  mounted() {
  },
}
</script>

<style lang="scss" scope>
#profile {
  padding: 40px 0 60px;
}
</style>
